import { ReduxDispatch } from '../interfaces/Redux';
import { ReduxStore } from '../interfaces/store';
import {
  StoreObject,
  StoreObjectsFilterData
} from '../interfaces/store/StoreObject';
import {
  FilterValue,
  FilterSearchParamWithOrderKey
} from '../types/FilterSearchParam';

import { idToken } from '../selectors/sessionSelector';

import { searchAuctions } from '~/helpers/orchestration/auctions';
import { getBrands } from '~/helpers/orchestration/auction';

import { serializeFilters } from '~/helpers/storeObjects';
import { createFilterOption } from '~/helpers/filterTranslation';
import {
  dynamicFilterOptions,
  facilityFilterOptionsSelector
} from '../selectors/storeObjectsSelectors';
import { sortBy } from '~/helpers/sortBy';
import { getFacilities } from '~/helpers/orchestration/facilities';
import { FILTER_PAGE_STEP_SIZE } from '~/config/constants';
import { MainCategory } from '../types/MainCategoryTypes';
import { log } from '~/helpers/bugsnagHelper';

export const fetchStoreObjectsStart = () => ({
  type: 'FETCH_STORE_ITEMS_START' as const
});

export const fetchStoreObjectsSuccess = (
  entities: Record<string, StoreObject>,
  searchParamKey: string,
  filters: StoreObjectsFilterData
) => ({
  type: 'FETCH_STORE_ITEMS_SUCCESS' as const,
  payload: { entities, searchParamKey, filters }
});

export const fetchStoreObjectsFailure = (error: string) => ({
  type: 'FETCH_STORE_ITEMS_FAILURE' as const,
  payload: error
});

export type FetchStoreObjectsAction =
  | ReturnType<typeof fetchStoreObjectsStart>
  | ReturnType<typeof fetchStoreObjectsSuccess>
  | ReturnType<typeof fetchStoreObjectsFailure>;

const FETCH_TIMEOUT_MS = 10000; // 10 seconds timeout

export const fetchStoreObjects =
  ({
    filters,
    limit = FILTER_PAGE_STEP_SIZE,
    offset = 0,
    signal
  }: {
    filters: Record<FilterSearchParamWithOrderKey, string>;
    limit?: number;
    offset?: number;
    signal?: AbortSignal;
  }) =>
  async (dispatch: ReduxDispatch, getState: () => ReduxStore) => {
    const state = getState();
    const token = idToken(state);
    const searchParamKey = serializeFilters({ ...filters });

    dispatch(fetchStoreObjectsStart());

    // Set up timeout to detect stuck requests
    const timeoutId = setTimeout(() => {
      log(new Error('Store objects fetch timeout'), {
        severity: 'warning',
        message: 'Store objects fetch potentially stuck',
        searchParamKey,
        offset,
        limit,
        timeoutMs: FETCH_TIMEOUT_MS
      });
    }, FETCH_TIMEOUT_MS);

    try {
      const response = await searchAuctions({
        cancellable: true,
        idToken: token,
        params: filters as Record<string, string>,
        limit: limit,
        offset,
        signal
      });

      if (!response) {
        return;
      }

      const entities = new Map<string, StoreObject>();
      response.auctions.forEach((item: StoreObject) => {
        entities.set(item.id, item);
      });

      const filtered: StoreObjectsFilterData = {
        ids: response.auctions.map((item: StoreObject) => item.id),
        hits: response.hits,
        total: response.total
      };

      dispatch(
        fetchStoreObjectsSuccess(
          Object.fromEntries(entities),
          searchParamKey,
          filtered
        )
      );

      clearTimeout(timeoutId); // Clear timeout on success
    } catch (error) {
      clearTimeout(timeoutId); // Clear timeout on error
      dispatch(fetchStoreObjectsFailure(error.message || 'An error occurred.'));

      // Only log if it's not an aborted request
      if (error.name !== 'AbortError') {
        log(error, {
          severity: 'error',
          message: 'Store objects fetch failed',
          searchParamKey,
          offset,
          limit
        });
      }
    }
  };

const fetchDynamicFilterStart = () => ({
  type: 'FETCH_DYNAMIC_FILTERS_START' as const
});

const fetchDynamicFilterValuesSuccess = (payload: {
  mainCategory: MainCategory;
  brands: FilterValue[];
  familyNames: Record<string, FilterValue[]>;
}) => ({
  type: 'FETCH_DYNAMIC_FILTERS_SUCCESS' as const,
  payload
});

const fetchDynamicFilterValuesFailure = (payload: unknown) => ({
  type: 'FETCH_DYNAMIC_FILTERS_FAILURE' as const,
  payload
});

export type FetchDynamicFilterValues =
  | ReturnType<typeof fetchDynamicFilterStart>
  | ReturnType<typeof fetchDynamicFilterValuesSuccess>
  | ReturnType<typeof fetchDynamicFilterValuesFailure>;

export const fetchDynamicFilterValues =
  (
    mainCategory: MainCategory,
    t: (key: string) => string,
    signal?: AbortSignal
  ) =>
  async (dispatch: ReduxDispatch, getState: () => ReduxStore) => {
    const currentDynamicFilterValues = dynamicFilterOptions(getState());

    // if data exists in store, do not fetch again
    if (
      currentDynamicFilterValues[mainCategory]?.brands.length > 0 &&
      Object.keys(currentDynamicFilterValues[mainCategory]?.familyNames)
        .length > 0
    ) {
      return;
    }

    dispatch(fetchDynamicFilterStart());

    try {
      const brandsModels = await getBrands({
        vehicleType: mainCategory,
        signal
      });

      const brands = Object.keys(brandsModels)
        .map(brand => createFilterOption(t, brand, brand, 'brand'))
        .sort(sortBy('label'));

      const models: Record<string, FilterValue[]> = Object.entries(
        brandsModels
      ).reduce((acc, [brand, models]) => {
        acc[brand] = models
          .map(model => createFilterOption(t, model, model, 'familyName'))
          .sort(sortBy('label'));
        return acc;
      }, {} as Record<string, FilterValue[]>);

      dispatch(
        fetchDynamicFilterValuesSuccess({
          mainCategory: mainCategory,
          brands,
          familyNames: models
        })
      );
    } catch (error) {
      dispatch(fetchDynamicFilterValuesFailure(error));
    }
  };

const fetchFacilitiesStart = () => ({
  type: 'FETCH_FACILITIES_START' as const
});

const fetchFacilitiesSuccess = (payload: FilterValue[]) => ({
  type: 'FETCH_FACILITIES_SUCCESS' as const,
  payload
});

const fetchFacilitiesFailure = (payload: unknown) => ({
  type: 'FETCH_FACILITIES_FAILURE' as const,
  payload
});

export type FetchFacilitiesAction =
  | ReturnType<typeof fetchFacilitiesStart>
  | ReturnType<typeof fetchFacilitiesSuccess>
  | ReturnType<typeof fetchFacilitiesFailure>;

export const fetchFacilities =
  (signal?: AbortSignal) =>
  async (dispatch: ReduxDispatch, getState: () => ReduxStore) => {
    const existingFacilities = facilityFilterOptionsSelector(getState());

    if (existingFacilities.length > 0) {
      return;
    }

    dispatch(fetchFacilitiesStart());

    try {
      const facilities = await getFacilities(
        {
          params: { facilityService: 'DELIVERY' }
        },
        signal
      );

      const facilityFilterOptions: FilterValue[] = facilities
        .map(facility => ({
          label: facility.name,
          value: facility.id,
          searchParamKey: 'facilities' as const
        }))
        .sort(sortBy('label'));

      dispatch(fetchFacilitiesSuccess(facilityFilterOptions));
    } catch (error) {
      dispatch(fetchFacilitiesFailure(error));
    }
  };

export const setFilterPageActiveAction = (payload: boolean) => ({
  type: 'FILTER_PAGE_REMEMBER_SCROLL_ACTIVE' as const,
  payload
});

export const setFilterPageScrollData = (payload: {
  auctionId?: string | null;
  url?: string | null;
}) => ({
  type: 'FILTER_PAGE_SET_SCROLL_DATA' as const,
  payload
});

export type StoreObjectsAction =
  | FetchStoreObjectsAction
  | FetchDynamicFilterValues
  | FetchFacilitiesAction
  | ReturnType<typeof setFilterPageActiveAction>
  | ReturnType<typeof setFilterPageScrollData>;
